exports.components = {
  "component---src-pages-account-index-js": () => import("./../../../src/pages/Account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-card-default-js": () => import("./../../../src/pages/Card/default.js" /* webpackChunkName: "component---src-pages-card-default-js" */),
  "component---src-pages-card-digio-js": () => import("./../../../src/pages/Card/digio.js" /* webpackChunkName: "component---src-pages-card-digio-js" */),
  "component---src-pages-card-multistep-js": () => import("./../../../src/pages/Card/multistep.js" /* webpackChunkName: "component---src-pages-card-multistep-js" */),
  "component---src-pages-landing-index-js": () => import("./../../../src/pages/Landing/index.js" /* webpackChunkName: "component---src-pages-landing-index-js" */),
  "component---src-pages-listing-index-js": () => import("./../../../src/pages/Listing/index.js" /* webpackChunkName: "component---src-pages-listing-index-js" */),
  "component---src-pages-loan-index-js": () => import("./../../../src/pages/Loan/index.js" /* webpackChunkName: "component---src-pages-loan-index-js" */),
  "component---src-pages-widget-index-js": () => import("./../../../src/pages/Widget/index.js" /* webpackChunkName: "component---src-pages-widget-index-js" */)
}

